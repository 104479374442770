import React from "react";

// Chakra imports
import {Box, Flex, Image, Text, useColorModeValue} from "@chakra-ui/react";
import banner from "assets/img/auth/auth.png";
// Custom components
import { HSeparator } from "components/separator/Separator";

export function SidebarBrand() {
  //   Chakra color mode
  let logoColor = useColorModeValue("navy.700", "white");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  return (
    <Flex align='center' direction='column'>
      {/*<ESDLogo h='30px' w='175px' my='32px' color={logoColor} />*/}
        <Box>
            <Flex px='25px' justify='space-between' mb='20px' align='center'>
                <Image src={banner} w='70px' h='70px' ></Image>
                <Text
                    color={textColor}
                    fontSize='35px'
                    fontWeight='700'
                    lineHeight='100%'>
                    RMHC HK
                </Text>
            </Flex>
        </Box>
      <HSeparator mb='20px' />
    </Flex>
  );
}

export default SidebarBrand;
