import React, {useEffect, useState} from "react";
import {NavLink, useHistory} from "react-router-dom";
// Chakra imports
import {
    AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay,
    Box,
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Icon, Image,
    Input,
    InputGroup,
    InputRightElement,
    Text, useColorMode,
    useColorModeValue,
} from "@chakra-ui/react";
// Custom components
// Assets
import {MdOutlineRemoveRedEye} from "react-icons/md";
import {RiEyeCloseLine} from "react-icons/ri";
import AuthService from "../../../axios/service/AuthService";
import banner from "../../../assets/img/auth/logo_color.png";
import blackBanner from "../../../assets/img/auth/logo_color.png";
import {IoMdMoon, IoMdSunny} from "react-icons/io";
let showBanner = banner
function SignIn() {
    const history = useHistory()
    useEffect(() => {
    },[])

    // Chakra color mode
    const textColor = useColorModeValue("navy.700", "white");
    const textColorSecondary = "gray.400";
    const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
    const textColorBrand = useColorModeValue("brand.500", "white");
    const brandStars = useColorModeValue("brand.500", "brand.400");
    const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
    const googleText = useColorModeValue("navy.700", "white");
    const googleHover = useColorModeValue(
        {bg: "gray.200"},
        {bg: "whiteAlpha.300"}
    );
    const googleActive = useColorModeValue(
        {bg: "secondaryGray.300"},
        {bg: "whiteAlpha.200"}
    );
    const [show, setShow] = React.useState(false);

    const [email, setEmail] = useState('');

    const [password, setPassword] = useState('');

    const cancelRef = React.useRef()

    const [invalidAuth, setInvalidAuth] = useState(false);

    const [loginLoading,setLoginLoading] = useState(false)
    const invalidAuthDialogOnClose = () => {
        setInvalidAuth(true);
    };

    const validAuthDialogOnClose = () => {
        setInvalidAuth(false);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleClick = () => setShow(!show);

    const checkPassword = () => {
        setLoginLoading(true)
        const loginUser = {
            email: email,
            password: password
        }
        AuthService.signIn(loginUser).then(response => {
            console.log("SignIn response ",response)
            setLoginLoading(false)
            if (response.data) {
                const session = response.data.session
                const email = response.data.user.email
                localStorage.setItem('authenticated',session.access_token)
                localStorage.setItem('username',email)
                history.push("/admin/dashboard")
                AuthService.updateLast()
                // localStorage.setItem('role',response.data.role)
            }else {
                setInvalidAuth(true)
            }
        }).catch(e => {
            setLoginLoading(false)
            setInvalidAuth(true)
        })
    }


    return (
        <Flex position='relative' h='max-content'>
            <Box display="flex" justifyContent="center" alignItems="center" width="100%" marginTop='10vh'>
                {/* 这里放置你要居中的内容 */}

            <Flex
                maxW={{base: "100%", md: "max-content"}}
                w='100%'
                mx={{base: "auto", lg: "0px"}}
                me='auto'
                h='100%'
                alignItems='start'
                justifyContent='center'
                mb={{base: "30px", md: "60px"}}
                px={{base: "25px", md: "0px"}}

                mt={{base: "40px", md: "14vh"}}
                flexDirection='column'>
                <Box >
                    <Heading color={textColor} fontSize='36px' mb='10px'>
                        Sign In
                    </Heading>
                    <Text
                        mb='36px'
                        ms='4px'
                        color={textColorSecondary}
                        fontWeight='400'
                        fontSize='md'>
                        Enter your email and password to sign in!
                    </Text>
                </Box>
                <Flex
                    zIndex='2'
                    direction='column'
                    w={{base: "100%", md: "420px"}}
                    maxW='100%'
                    background='transparent'
                    borderRadius='15px'
                    mx={{base: "auto", lg: "unset"}}
                    me='auto'
                    mb={{base: "20px", md: "auto"}}>
                    {/*<Button*/}
                    {/*  fontSize='sm'*/}
                    {/*  me='0px'*/}
                    {/*  mb='26px'*/}
                    {/*  py='15px'*/}
                    {/*  h='50px'*/}
                    {/*  borderRadius='16px'*/}
                    {/*  bg={googleBg}*/}
                    {/*  color={googleText}*/}
                    {/*  fontWeight='500'*/}
                    {/*  _hover={googleHover}*/}
                    {/*  _active={googleActive}*/}
                    {/*  _focus={googleActive}>*/}
                    {/*  <Icon as={FcGoogle} w='20px' h='20px' me='10px' />*/}
                    {/*  Sign in with Google*/}
                    {/*</Button>*/}
                    {/*<Flex align='center' mb='25px'>*/}
                    {/*    <HSeparator/>*/}
                    {/*    <Text color='gray.400' mx='14px'>*/}
                    {/*        or*/}
                    {/*    </Text>*/}
                    {/*    <HSeparator/>*/}
                    {/*</Flex>*/}
                    <FormControl>
                        <FormLabel
                            display='flex'
                            ms='4px'
                            fontSize='sm'
                            fontWeight='500'
                            color={textColor}
                            mb='8px'>
                            Email<Text color={brandStars}>*</Text>
                        </FormLabel>
                        <Input
                            isRequired={true}
                            variant='auth'
                            fontSize='sm'
                            ms={{base: "0px", md: "0px"}}
                            type='email'
                            placeholder='email'
                            mb='24px'
                            fontWeight='500'
                            size='lg'
                            value={email}
                            onChange={handleEmailChange}
                        />
                        <FormLabel
                            ms='4px'
                            fontSize='sm'
                            fontWeight='500'
                            color={textColor}
                            display='flex'>
                            Password<Text color={brandStars}>*</Text>
                        </FormLabel>
                        <InputGroup size='md'>
                            <Input
                                isRequired={true}
                                fontSize='sm'
                                placeholder='Password'
                                mb='24px'
                                size='lg'
                                type={show ? "text" : "password"}
                                variant='auth'
                                value={password}
                                onChange={handlePasswordChange}
                            />
                            <InputRightElement display='flex' alignItems='center' mt='4px'>
                                <Icon
                                    color={textColorSecondary}
                                    _hover={{cursor: "pointer"}}
                                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                                    onClick={handleClick}
                                />
                            </InputRightElement>
                        </InputGroup>
                        <Flex justifyContent='space-between' align='center' mb='24px'>
                            {/*<FormControl display='flex' alignItems='center'>*/}
                            {/*    <Checkbox*/}
                            {/*        id='remember-login'*/}
                            {/*        colorScheme='brandScheme'*/}
                            {/*        me='10px'*/}
                            {/*    />*/}
                            {/*    <FormLabel*/}
                            {/*        htmlFor='remember-login'*/}
                            {/*        mb='0'*/}
                            {/*        fontWeight='normal'*/}
                            {/*        color={textColor}*/}
                            {/*        fontSize='sm'>*/}
                            {/*        Keep me logged in*/}
                            {/*    </FormLabel>*/}
                            {/*</FormControl>*/}
                            {/*<NavLink to='/auth/forgot-password'>*/}
                            {/*  <Text*/}
                            {/*    color={textColorBrand}*/}
                            {/*    fontSize='sm'*/}
                            {/*    w='124px'*/}
                            {/*    fontWeight='500'>*/}
                            {/*    Forgot password?*/}
                            {/*  </Text>*/}
                            {/*</NavLink>*/}
                        </Flex>
                        <Button
                            fontSize='sm'
                            variant='brand'
                            fontWeight='500'
                            w='100%'
                            h='50'
                            mb='24px'
                            isLoading={loginLoading}
                            onClick={() => checkPassword()}
                        >
                            Sign In
                        </Button>
                    </FormControl>
                    <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="30vh">
                        <Image src={showBanner} w='200px' h='200px' ></Image>
                    </Box>

                </Flex>

            </Flex>
            </Box>
            <AlertDialog
                isOpen={invalidAuth}
                leastDestructiveRef={cancelRef}
                onClose={invalidAuthDialogOnClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Invalid Email or Password!
                        </AlertDialogHeader>

                        {/*<AlertDialogBody>*/}
                        {/*    Are you sure deleted this banner?*/}
                        {/*</AlertDialogBody>*/}

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={validAuthDialogOnClose}>
                                OK
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
            <FixedPlugin />
        </Flex>
    );
}

function FixedPlugin(props) {
    const { ...rest } = props;
    const { colorMode, toggleColorMode } = useColorMode();
    let bgButton = "linear-gradient(135deg, #868CFF 0%, #4318FF 100%)";
    const [index,setIndex] = useState(0)
    const images = [banner,blackBanner]
    const handleToggle = () => {
        toggleColorMode()
        const nextIndex = index === 0 ? 1 : 0
        setIndex(nextIndex)
        showBanner = images[nextIndex]

    }

    return (
        <Button
            {...rest}
            h='60px'
            w='60px'
            zIndex='99'
            bg={bgButton}
            position='fixed'
            variant='no-effects'
            left={document.documentElement.dir === "rtl" ? "35px" : ""}
            right={document.documentElement.dir === "rtl" ? "" : "35px"}
            bottom='30px'
            border='1px solid'
            borderColor='#6A53FF'
            borderRadius='50px'
            onClick={handleToggle}
            display='flex'
            p='0px'
            align='center'
            justify='center'>
            <Icon
                h='24px'
                w='24px'
                color='white'
                as={colorMode === "light" ? IoMdMoon : IoMdSunny}
            />
        </Button>
    );
}
export default SignIn;
